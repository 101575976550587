import type { PropsWithChildren } from "react";
import { useRef } from "react";
import React from "react";
import type { NumberFieldStateOptions } from "@react-stately/numberfield";
import { useButton } from "@react-aria/button";
import { polymorphic } from "../../utils/ref";
import { noop } from "../../utils/events";
import { styled } from "../../utils/system/factory";
import { Center } from "../Layout/Center";
import type { Sx } from "../../utils/types/system";
import { Flex } from "../Layout/Flex";
import type { BaseInputProps } from "./Input/BaseInput";
import { BaseInput } from "./Input/BaseInput";
import { useNumberInput } from "./NumberInput/useNumberInput";

export type NumberInputProps = Omit<
  BaseInputProps,
  "defaultValue" | "leftIcon" | "onChange" | "step" | "value"
> &
  Omit<NumberFieldStateOptions, "locale"> & {
    onChange: (value: number) => void;
    value?: number;
    withStepperButtons?: boolean;
  };

const StyledCounterButtonGroup = styled(Flex, {
  base: ({ theme }) => ({
    border: `1px solid ${theme.colors.neutral[30]}`,
    borderBottomRightRadius: theme.radius.base,
    borderLeft: "none",
    borderTopRightRadius: theme.radius.base,
    flexShrink: 0,
    height: theme.spacing[40],
    overflow: "hidden",
  }),
});

const StyledCounterButton = styled(Center, {
  base: ({ theme }) => ({
    "&:hover": {
      background: theme.colors.neutral.light,
    },
    background: theme.colors.neutral.light,
    color: theme.colors.text.secondary,
    cursor: "pointer",
    flex: 1,
    height: "100%",
    minWidth: 36,
  }),
});

const StyledIcon = styled("svg", {
  base: ({ theme }) => ({
    flex: 1,
    height: theme.spacing[16],
    minWidth: theme.spacing[24],
    strokeWidth: 3,
  }),
});

const InputCounterButton = ({
  children,
  sx,
  ...props
}: PropsWithChildren<{ sx?: Sx }>) => {
  const ref = useRef<HTMLDivElement>(null);
  const { buttonProps } = useButton(props, ref);

  return (
    <StyledCounterButton {...buttonProps} ref={ref} sx={sx}>
      {children}
    </StyledCounterButton>
  );
};

export const NumberInput = polymorphic<"input", NumberInputProps>(
  ({ hintRight, sx, withStepperButtons, ...props }, ref) => {
    const {
      decrementButtonProps,
      groupProps,
      incrementButtonProps,
      inputProps,
      inputRef,
      onInputChange,
    } = useNumberInput(props);

    return (
      <BaseInput
        {...groupProps}
        aria-labelledby={props["aria-labelledby"]}
        buttonsElement={
          withStepperButtons && (
            <StyledCounterButtonGroup className="input-buttons">
              <InputCounterButton
                {...decrementButtonProps}
                sx={(theme) => ({
                  borderRight: `1px solid ${theme.colors.neutral[10]}`,
                })}
              >
                <StyledIcon
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 12h14" />
                </StyledIcon>
              </InputCounterButton>
              <InputCounterButton {...incrementButtonProps}>
                <StyledIcon
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 12h14" />
                  <path d="M12 5v14" />
                </StyledIcon>
              </InputCounterButton>
            </StyledCounterButtonGroup>
          )
        }
        hintRight={hintRight}
        innerRef={inputRef}
        inputProps={inputProps}
        isDisabled={props.isDisabled}
        isErrored={props.isErrored}
        isLoading={props.isLoading}
        onChange={noop}
        onChangeEvent={onInputChange}
        ref={ref}
        sx={sx}
      />
    );
  },
);

NumberInput.displayName = "NumberInput";
