import { merge } from "lodash-es";
import type { CSSProperties } from "react";
import type { Theme } from "../../theme/theme";
import { spacingProps } from "../system/spacing";

export type SpacingScale = keyof {
  [key in keyof Theme["spacing"]]: Theme["spacing"][key];
};

export type StyleSystemProps = Partial<
  Record<keyof typeof spacingProps, SpacingScale>
> &
  Pick<CSSProperties, "alignContent">;

export type StyleSystemKeys = "spacing";

type PropertyMapper<Property extends keyof CSSProperties> = Record<
  Property,
  (
    theme: Theme,
    value: CSSProperties[Property],
  ) => Pick<CSSProperties, Property>
>;

const flowProps: PropertyMapper<"alignContent"> = {
  alignContent: (_, alignContent) => ({ alignContent }),
};

export const styleSystemProps = merge({}, spacingProps, flowProps);
