import Fuse from "fuse.js";
import { removeContainerTypesFromOvrseaName } from "./removeContainerTypesFromOvrseaName";
import { listRateTemplates } from "./listRateTemplates";
import type { RateTemplate } from ".";

export const findClientNameInRateTemplate =
  (rateTemplates: RateTemplate[]) => (ovrseaName: string) => {
    const exactMatch = rateTemplates.find(
      (template) => template.ovrseaName === ovrseaName,
    );

    const ovrseaNameWithoutContainerType =
      removeContainerTypesFromOvrseaName(ovrseaName);

    if (exactMatch) {
      return exactMatch.clientName;
    }

    const fuse = new Fuse(rateTemplates, {
      includeMatches: true,
      includeScore: true,
      isCaseSensitive: false,
      keys: ["ovrseaName"],
      minMatchCharLength: 3,
      threshold: 0.4,
    });

    const [bestMatch] = fuse.search(ovrseaNameWithoutContainerType);

    if (!bestMatch) {
      return ovrseaName;
    }

    return bestMatch.item.clientName;
  };

export const findMatchingClientName = (ovrseaName: string) => {
  const rateTemplates = listRateTemplates();

  return findClientNameInRateTemplate(rateTemplates)(ovrseaName);
};
