/* eslint-disable no-use-before-define */

/**
 * Usage examples in packages/ovrutils/src/maybe/__tests__/maybe.test.ts
 */

type MaybeInterface<ResultType, ErrorReasonType> = {
  asyncChain: <ReturnType>(
    fn: (result: ResultType) => Promise<Maybe<ReturnType, ErrorReasonType>>,
  ) => Promise<Maybe<ReturnType, ErrorReasonType>>;
  chain: <ReturnType>(
    fn: (result: ResultType) => Maybe<ReturnType, ErrorReasonType>,
  ) => Maybe<ReturnType, ErrorReasonType>;
};

export type Error<ResultType, ErrorReasonType> = {
  reason: ErrorReasonType;
  type: "error";
} & MaybeInterface<ResultType, ErrorReasonType>;

export type Success<ResultType, ErrorReasonType> = {
  result: ResultType;
  type: "success";
} & MaybeInterface<ResultType, ErrorReasonType>;

export type Maybe<ResultType, ErrorReasonType> =
  | Error<ResultType, ErrorReasonType>
  | Success<ResultType, ErrorReasonType>;

export const isSuccess = <ResultType, ErrorReasonType>(
  maybe: Maybe<ResultType, ErrorReasonType>,
): maybe is Success<ResultType, ErrorReasonType> => maybe.type === "success";

export const isError = <ResultType, ErrorReasonType>(
  maybe: Maybe<ResultType, ErrorReasonType>,
): maybe is Error<ResultType, ErrorReasonType> => maybe.type === "error";

export const success = <ResultType, ErrorReasonType>(
  result: ResultType,
): Success<ResultType, ErrorReasonType> => ({
  asyncChain: async (fn) => fn(result),
  chain: (fn) => fn(result),
  result,
  type: "success",
});

export const error = <ResultType, ErrorReasonType>(
  reason: ErrorReasonType,
): Error<ResultType, ErrorReasonType> => ({
  asyncChain: async () => error(reason),
  chain: () => error(reason),
  reason,
  type: "error",
});
