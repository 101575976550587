import { clientNames, ovrseaNames, rateTemplates } from "./data/rateTemplates";
import type { RateTemplate } from ".";

export const listOvrseaNameOptions = (): string[] => {
  return ovrseaNames as unknown as string[];
};

export const listClientNameOptions = (): string[] => {
  return clientNames as unknown as string[];
};

export const listRateTemplates = (): RateTemplate[] => {
  const rateTemplatesArray = Object.entries(rateTemplates).map(
    ([ovrseaName, clientName]) => ({
      clientName,
      ovrseaName,
    }),
  );

  return rateTemplatesArray;
};
