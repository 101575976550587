export const ovrseaNamesAreTheSame = (
  ovrseaName1: string,
  ovrseaName2: string,
): boolean => {
  const ovrseaName1WithoutParenthesisContent = ovrseaName1
    .replace(/\(.*$/, "")
    .trim();
  const cleanOvrseaName1 = ovrseaName1WithoutParenthesisContent.replace(
    /[^\w\s]/gi,
    "",
  );
  const ovrseaName2WithoutParenthesisContent = ovrseaName2
    .replace(/\(.*$/, "")
    .trim();
  const cleanOvrseaName2 = ovrseaName2WithoutParenthesisContent.replace(
    /[^\w\s]/gi,
    "",
  );

  return cleanOvrseaName1 === cleanOvrseaName2;
};
