import type { MouseEventHandler } from "react";
import React from "react";
import { noop } from "../../utils/events";
import { polymorphic } from "../../utils/ref";
import { useBooleanState } from "../../utils/hooks/useBooleanState";
import { useDependenciesContext } from "../../theme/dependencies";
import type { ButtonProps } from "./Button";
import { Button } from "./Button";
import type { IconButtonProps } from "./IconButton";
import { IconButton } from "./IconButton";

export const CancelButton = polymorphic<typeof Button, Partial<ButtonProps>>(
  (props, ref) => {
    const { messages } = useDependenciesContext();

    return (
      <Button
        type="outlined"
        {...props}
        label={props.label ?? messages.button.cancel}
        ref={ref}
      />
    );
  },
);
CancelButton.displayName = "CancelButton";

export const SubmitButton = polymorphic<typeof Button, Partial<ButtonProps>>(
  (props, ref) => {
    const { messages } = useDependenciesContext();

    return (
      <Button
        buttonType="submit"
        {...props}
        label={props.label ?? messages.button.submit}
        ref={ref}
      />
    );
  },
);
SubmitButton.displayName = "SubmitButton";

export const DeleteButton = polymorphic<typeof Button, Partial<ButtonProps>>(
  (props, ref) => {
    const { messages } = useDependenciesContext();

    return (
      <Button
        color="danger"
        icon="trash"
        iconPosition="left"
        {...props}
        label={props.label ?? messages.button.delete}
        ref={ref}
      />
    );
  },
);
DeleteButton.displayName = "DeleteButton";

export const DownloadButton = polymorphic<typeof Button, Partial<ButtonProps>>(
  (props, ref) => {
    const { messages } = useDependenciesContext();

    return (
      <Button
        icon="cloud-download-alt"
        iconPosition="left"
        {...props}
        label={props.label ?? messages.button.download}
        ref={ref}
      />
    );
  },
);
DownloadButton.displayName = "DownloadButton";

export const CloseButton = polymorphic<typeof Button, Partial<ButtonProps>>(
  (props, ref) => {
    const { messages } = useDependenciesContext();

    return (
      <Button
        icon="cross"
        iconPosition="left"
        type="outlined"
        {...props}
        label={props.label ?? messages.button.close}
        ref={ref}
      />
    );
  },
);
CloseButton.displayName = "CloseButton";

export const CopyButton = polymorphic<typeof Button, Partial<ButtonProps>>(
  ({ copyLabel, label, onClick = noop, ...rest }, ref) => {
    const { messages } = useDependenciesContext();
    const [hasCopied, setCopied, setNotCopied] = useBooleanState();

    const onCopy: MouseEventHandler<HTMLButtonElement> = (event) => {
      setCopied();
      setTimeout(setNotCopied, 2000);
      onClick(event);
    };

    const hasCopiedMessage = messages.button.hasCopied;
    const copyMessage = copyLabel ?? messages.button.copy;

    return (
      <Button
        icon="copy"
        iconPosition="left"
        label={hasCopied ? hasCopiedMessage : (label ?? copyMessage)}
        {...rest}
        onClick={onCopy}
        ref={ref}
      />
    );
  },
);
CopyButton.displayName = "CopyButton";

export const CopyIconButton = polymorphic<
  typeof Button,
  Partial<IconButtonProps>
>(({ copyLabel, isDisabled, label, onClick = noop, ...rest }, ref) => {
  const { messages } = useDependenciesContext();
  const [hasCopied, setCopied, setNotCopied] = useBooleanState();

  const onCopy: MouseEventHandler<HTMLButtonElement> = (event) => {
    setCopied();
    setTimeout(setNotCopied, 2000);
    onClick(event);
  };

  const hasCopiedMessage = messages.button.hasCopied;
  const copyMessage = copyLabel ?? messages.button.copy;

  return (
    <IconButton
      isDisabled={isDisabled || hasCopied}
      label={hasCopied ? hasCopiedMessage : copyMessage}
      {...rest}
      name={hasCopied ? ("check-circle" as const) : ("copy" as const)}
      onClick={onCopy}
      ref={ref}
    />
  );
});
CopyIconButton.displayName = "CopyIconButton";
