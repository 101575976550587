export const OVRSEA_NAMES = {
  APUREMENT: "Apurement",
  AWB: "AWB",
  CYNOTECHNICAL_SCREENING: "Cynotechnical Screening",
  DANGEROUS_GOODS_SURCHARGE: "Dangerous Goods Surcharge",
  DELIVERY: "Delivery",
  DOCUMENTATION: "Documentation",
  DROP_OFF: "Drop Off",
  EU_ETS: "EU ETS Surcharge",
  EUR1: "EUR1",
  EXPORT_CUSTOMS_CLEARANCE: "Export Customs Clearance",
  FDA: "FDA",
  FISH_AND_WILDLIFE: "Fish and Wildlife",
  FREIGHT: "Freight",
  IMPORT_CUSTOMS_CLEARANCE: "Import Customs Clearance",
  ISF: "ISF",
  ODOROLOGY_SCREENING: "Odorology Screening",
  ON_CARRIAGE: "On-Carriage",
  PALLETIZATION: "Palletization",
  PICKUP: "Pickup",
  PRE_CARRIAGE: "Pre-Carriage",
  PROFIT_SHARE: "Profit Share",
  T1: "T1",
  TAD: "TAD",
  TAO: "TAO",
  THC: "THC",
  VARIABLE_CUSTOMS_CLEARANCE: "Variable Customs Clearance",
} as const;

export const specificOvrseaNames = Object.values(OVRSEA_NAMES);
