import { isOvrseaName, OVRSEA_NAMES } from "../../kronos/rateTemplates";
import type { DbPricingSteps } from "../../sharedTypes";

type MarginType = "at_cost" | "variable";

const priceIsInsurance = (category: DbPricingSteps) => category === "insurance";
const priceIsTruckMainPrice = (
  ovrseaName: string,
  category: DbPricingSteps,
) => {
  const priceIsDepartureTruckFreight =
    category === "departure_truck_freight" &&
    (isOvrseaName(OVRSEA_NAMES.PRE_CARRIAGE)(ovrseaName) ||
      isOvrseaName(OVRSEA_NAMES.PICKUP)(ovrseaName));
  const priceIsArrivalTruckFreight =
    category === "arrival_truck_freight" &&
    (isOvrseaName(OVRSEA_NAMES.ON_CARRIAGE)(ovrseaName) ||
      isOvrseaName(OVRSEA_NAMES.DELIVERY)(ovrseaName));

  return priceIsDepartureTruckFreight || priceIsArrivalTruckFreight;
};

const priceIsFreight = (ovrseaName: string, category: DbPricingSteps) =>
  isOvrseaName(OVRSEA_NAMES.FREIGHT)(ovrseaName) && category === "freight";
const priceIsProfitShare = isOvrseaName(OVRSEA_NAMES.PROFIT_SHARE);

export const determineMarginTypeFromNameAndCategory = ({
  category,
  ovrseaName,
  purchasePriceCentsInPriceCurrency,
}: {
  category: DbPricingSteps;
  ovrseaName: string;
  purchasePriceCentsInPriceCurrency: number;
}): MarginType | null => {
  // Prices with negative purchase price cents are considered as at_cost prices
  if (purchasePriceCentsInPriceCurrency < 0) {
    return "at_cost";
  }
  if (priceIsInsurance(category)) {
    return "variable";
  }
  if (priceIsTruckMainPrice(ovrseaName, category)) {
    return "variable";
  }
  if (priceIsFreight(ovrseaName, category)) {
    return "variable";
  }
  if (priceIsProfitShare(ovrseaName)) {
    return "at_cost";
  }

  return null;
};
