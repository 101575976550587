import emotionStyled from "@emotion/styled";
import { reduce } from "lodash-es";
import type { ThemingProps } from "../../utils/types/system";
import type { As, FactoryComponent } from "../../utils/types/polymorphic";
import type { OvrseaFactoryOptions } from "../../utils/system/factory";
import type {
  BaseStyleMapper,
  StyleResolverProps,
} from "../../utils/system/compose";
import { composeStyleMapper, composeSx } from "../../utils/system/compose";
import { styleSystemProps } from "../../utils/system/system";

const applyStyles =
  <P extends object = object>({
    base,
  }: {
    base?: BaseStyleMapper<P>;
  } = {}) =>
  ({ sx, theme, ...rest }: P & StyleResolverProps) => ({
    ...composeStyleMapper<P>(base, rest, theme),
    ...composeSx(sx, theme),
  });

const isSystemProp = (key: string): key is keyof typeof styleSystemProps =>
  key in styleSystemProps;

const translateSystemProps = ({ theme, ...styleProps }: StyleResolverProps) =>
  reduce(
    styleProps,
    (styles, cssValue, cssProperty) => {
      if (!isSystemProp(cssProperty)) {
        return styles;
      }

      const mapper = styleSystemProps[cssProperty];

      if (!mapper) {
        return styles;
      }

      return {
        ...styles,
        // mapper type is dependant of the keys it returns (e.g. spacing prop mapper is generic when flex prop mapper is not),
        // and here we are not aware of its type, so we probably can't type this correctly
        // @ts-expect-error
        ...mapper(theme, cssValue),
      };
    },
    {},
  );

/** `sx` style prop and global system props are mapped and serialized from object to class names with emotion */
const styledWrapper = <
  T extends As,
  P extends Pick<StyleResolverProps, "theme">,
>(
  component: T,
  options?: OvrseaFactoryOptions<P>,
) => {
  const { base, ...styledOptions } = options ?? {};

  const styles = applyStyles({ base });

  return emotionStyled(
    component as Parameters<typeof emotionStyled>[0],
    styledOptions as Parameters<typeof emotionStyled>[1],
  )((props: P & StyleResolverProps & ThemingProps) => ({
    ...styles(props),
    ...translateSystemProps(props),
  })) as FactoryComponent<T, P & StyleResolverProps>;
};

export { styledWrapper };
