/* eslint-disable immutable/no-let */

import { isNotDefined } from "@ovrsea/ovrutils";

type ScrollableElement = {
  element: HTMLElement;
  scrollLeft: number;
  scrollTop: number;
};

type FocusableElement = {
  focus(options?: { preventScroll?: boolean }): void;
};

const isDisabled = (element: HTMLElement) =>
  Boolean(element.getAttribute("disabled")) ||
  Boolean(element.getAttribute("aria-disabled"));

const isHidden = (element: HTMLElement) => {
  if (element.parentElement && isHidden(element.parentElement)) {
    return true;
  }

  return element.hidden;
};

const hasTabIndex = (element: HTMLElement) => element.hasAttribute("tabindex");

const focusableElList = [
  "input:not([disabled])",
  "select:not([disabled])",
  "textarea:not([disabled])",
  "embed",
  "iframe",
  "object",
  "a[href]",
  "area[href]",
  "button:not([disabled])",
  "[tabindex]",
  "audio[controls]",
  "video[controls]",
  "*[tabindex]:not([aria-disabled])",
  "*[contenteditable]",
];

const focusableTags = ["input", "select", "textarea", "button"];

const focusableElSelector = focusableElList.join();

const isElement = (el: any): el is Element =>
  el != null &&
  typeof el == "object" &&
  "nodeType" in el &&
  el.nodeType === Node.ELEMENT_NODE;

const selectNodeDocument = (node?: Element | null): Document =>
  isElement(node) ? (node.ownerDocument ?? document) : document;

const isHTMLElement = (el: any): el is HTMLElement => {
  if (!isElement(el)) {
    return false;
  }

  const win = el.ownerDocument.defaultView ?? window;

  return el instanceof win.HTMLElement;
};

const isFocusable = (element: HTMLElement) => {
  if (!isHTMLElement(element) || isHidden(element) || isDisabled(element)) {
    return false;
  }

  const { localName: htmlTagName } = element;

  if (focusableTags.indexOf(htmlTagName) >= 0) {
    return true;
  }

  if (htmlTagName === "a") {
    return element.hasAttribute("href");
  }

  return hasTabIndex(element);
};

const selectFocusableElements = <T extends HTMLElement>(container: T) => {
  const focusableEls = Array.from(
    container.querySelectorAll<T>(focusableElSelector),
  );

  focusableEls.unshift(container);

  return focusableEls
    .filter(isFocusable)
    .filter((el) => window.getComputedStyle(el).display !== "none");
};

const isInputElement = (
  element: FocusableElement,
): element is HTMLInputElement =>
  isHTMLElement(element) &&
  element.tagName.toLowerCase() === "input" &&
  "select" in element;

let supportsPreventScrollCached: boolean | null = null;

const supportsPreventScroll = () => {
  if (supportsPreventScrollCached == null) {
    supportsPreventScrollCached = false;

    try {
      const div = document.createElement("div");

      div.focus({
        get preventScroll() {
          supportsPreventScrollCached = true;

          return true;
        },
      });
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  return supportsPreventScrollCached;
};

const selectScrollableElements = (
  element: HTMLElement,
): ScrollableElement[] => {
  const doc = selectNodeDocument(element);
  const win = doc.defaultView ?? window;
  let parent = element.parentNode;

  const scrollableElements: ScrollableElement[] = [];
  const rootScrollingElement = doc.scrollingElement ?? doc.documentElement;

  while (parent instanceof win.HTMLElement && parent !== rootScrollingElement) {
    if (
      parent.offsetHeight < parent.scrollHeight ||
      parent.offsetWidth < parent.scrollWidth
    ) {
      scrollableElements.push({
        element: parent,
        scrollLeft: parent.scrollLeft,
        scrollTop: parent.scrollTop,
      });
    }
    parent = parent.parentNode;
  }

  if (rootScrollingElement instanceof win.HTMLElement) {
    scrollableElements.push({
      element: rootScrollingElement,
      scrollLeft: rootScrollingElement.scrollLeft,
      scrollTop: rootScrollingElement.scrollTop,
    });
  }

  return scrollableElements;
};

const restoreScrollPosition = (scrollableElements: ScrollableElement[]) => {
  for (const { element, scrollLeft, scrollTop } of scrollableElements) {
    element.scrollTop = scrollTop;
    element.scrollLeft = scrollLeft;
  }
};

const isActiveElement = (element: FocusableElement) => {
  const doc = isHTMLElement(element) ? selectNodeDocument(element) : document;

  return doc.activeElement === (element as HTMLElement);
};

const focusElement = (element: FocusableElement | null) => {
  if (!element || isActiveElement(element)) {
    return -1;
  }

  const triggerFocus = () => {
    if (isNotDefined(element)) {
      return;
    }
    if (supportsPreventScroll()) {
      element.focus({ preventScroll: true });
    } else {
      element.focus();
      const scrollableElements = selectScrollableElements(
        element as HTMLElement,
      );

      restoreScrollPosition(scrollableElements);
    }

    if (isInputElement(element)) {
      element.select();
    }
  };

  return requestAnimationFrame(triggerFocus);
};

export { focusElement, selectFocusableElements };
