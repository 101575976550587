/* eslint-disable perfectionist/sort-objects */
import React from "react";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";
import { styles } from "../../theme/commons";
import type { Theme } from "../../theme/theme";

type Size = keyof Theme["subtitle"];

export type SubtitleProps = {
  isTruncated?: boolean;
  size?: Size;
};

const StyledSubtitle = styled<"h3", SubtitleProps>("h3", {
  base: ({ isTruncated, size = "md", theme }) => {
    const { fontSize, fontWeight, letterSpacing, lineHeight } =
      theme.subtitle[size];

    return [
      {
        color: theme.colors.neutral.base,
        fontSize,
        fontWeight,
        lineHeight,
        letterSpacing,
      },
      isTruncated && styles.truncated,
    ];
  },
});

/** @deprecated use [typography components (Heading, Body, or Caption)]{@link https://design-system.ovrsea.com/?path=/docs/typography} */
export const Subtitle = polymorphic<"h4", SubtitleProps>(
  ({ as: Component = "h4", ...rest }, ref) => (
    <StyledSubtitle as={Component} ref={ref} {...rest} />
  ),
);

Subtitle.displayName = "Subtitle";
