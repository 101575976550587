import { includes, keyBy, sumBy } from "lodash-es";
import { isMatching } from "ts-pattern";
import type { EnumOrLiteral } from "../../typescriptHelpers/enumOrTypeLiteral";
import { OvrseaDataError } from "../../errors/OvrseaDataError";
import roundValue from "../../roundValue";
import { isDefined } from "../../other/isDefined";
import { isNotDefined } from "../../other/isNotDefined";
import type { DbPricingSteps } from "../../sharedTypes";

export type Currency =
  | "AED"
  | "AUD"
  | "BHD"
  | "BRL"
  | "CAD"
  | "CHF"
  | "CNY"
  | "EUR"
  | "GBP"
  | "HKD"
  | "IDR"
  | "INR"
  | "JPY"
  | "KRW"
  | "KWD"
  | "MYR"
  | "NZD"
  | "PLN"
  | "SEK"
  | "SGD"
  | "THB"
  | "TWD"
  | "USD"
  | "XOF"
  | "XPF"
  | "ZAR";

export type PriceOvrutils = {
  currency: EnumOrLiteral<Currency>;
  value: number;
  vatRate?: null | number;
};

export type InputPriceOvrutils = {
  currency: EnumOrLiteral<Currency>;
  value: number;
  vatRate?: null | number;
};

export type PriceForFreightRateCalculation = {
  ovrseaName: string;
  rate: null | number;
  rateCurrency: EnumOrLiteral<Currency> | null;
};

const currencyObj = {
  AED: "AED",
  AUD: "AUD",
  BHD: "BHD",
  BRL: "BRL",
  CAD: "CAD",
  CHF: "CHF",
  CNY: "CNY",
  EUR: "EUR",
  GBP: "GBP",
  HKD: "HKD",
  IDR: "IDR",
  INR: "INR",
  JPY: "JPY",
  KRW: "KRW",
  KWD: "KWD",
  MYR: "MYR",
  NZD: "NZD",
  PLN: "PLN",
  SEK: "SEK",
  SGD: "SGD",
  THB: "THB",
  TWD: "TWD",
  USD: "USD",
  XOF: "XOF",
  XPF: "XPF",
  ZAR: "ZAR",
} as const satisfies Record<Currency, Currency>;

export const currencies = Object.keys(currencyObj) as Currency[];

export const PRICE_CATEGORIES = [
  "pre_carriage",
  "departure_fees",
  "freight",
  "arrival_fees",
  "on_carriage",
  "customs",
  "insurance",
  "others",
] as const;
export type PriceCategory = (typeof PRICE_CATEGORIES)[number];

export type PriceBreakdown = Record<PriceCategory, number>;

export const mapPriceCategories: Record<DbPricingSteps, PriceCategory> = {
  arrival_customs: "arrival_fees",
  arrival_fees: "arrival_fees",
  arrival_logistics: "arrival_fees",
  arrival_truck_freight: "on_carriage",
  carbon_offset: "others",
  customs: "customs",
  departure_customs: "departure_fees",
  departure_fees: "departure_fees",
  departure_logistics: "departure_fees",
  departure_truck_freight: "pre_carriage",
  freight: "freight",
  insurance: "insurance",
  other: "others",
  other_services: "others",
};

export const stringIsCurrency = (
  str: null | string | undefined,
): str is Currency => includes(currencies, str);

export type ExchangeRateOvrutils = {
  from: EnumOrLiteral<Currency>;
  to: EnumOrLiteral<Currency>;
  value: number;
};

export type InputExchangeRateOvrutils = {
  from: EnumOrLiteral<Currency>;
  to: EnumOrLiteral<Currency>;
  value: number;
};

export const findExchangeRate = <T extends InputExchangeRateOvrutils>(
  exchangeRates: T[],
  from: EnumOrLiteral<Currency>,
  to: EnumOrLiteral<Currency>,
): number => {
  if (from === to) {
    return 1;
  }
  if (exchangeRates.length === 0) {
    throw new OvrseaDataError(
      `OVRUTILS :: no exchange rates : Missing exchange rate from ${from} to ${to}`,
    );
  }
  const exchangeRate = exchangeRates.find(
    (e) => e.from === from && e.to === to,
  );

  if (exchangeRate) {
    return exchangeRate.value;
  }
  const inverseExchangeRate = exchangeRates.find(
    (e) => e.to === from && e.from === to,
  );

  if (inverseExchangeRate) {
    return 1 / inverseExchangeRate.value;
  }
  throw new OvrseaDataError(
    `OVRUTILS :: Missing exchange rate from ${from} to ${to}`,
  );
};

export const selectUsdToEurExchangeRate = (
  exchangeRates: InputExchangeRateOvrutils[],
): number => {
  const conversionRateFromUsdToEur = exchangeRates.find(
    isMatching({ from: "USD", to: "EUR" }),
  )?.value;

  if (!conversionRateFromUsdToEur) {
    throw new Error(`Conversion rate from USD to EUR not found in final check`);
  }

  return conversionRateFromUsdToEur;
};

/**
 * @param total
 * @param vatRate
 * @param withVat
 */
export const computeVAT = ({
  total,
  vatRate,
  withVat,
}: {
  total: number;
  vatRate?: null | number;
  withVat?: boolean;
}) => {
  if (withVat && isDefined(vatRate)) {
    return (vatRate * total) / 100;
  }

  return 0;
};

export type PriceConversionArgs = {
  conversionRate: number;
  price: InputPriceOvrutils;
  withVat?: boolean;
};

export const calculatePriceInCurrency = (args: PriceConversionArgs) => {
  const totalAfterConversion = args.price.value / args.conversionRate;
  const vatValue = computeVAT({
    total: totalAfterConversion,
    vatRate: args.price.vatRate,
    withVat: args.withVat,
  });

  return totalAfterConversion + vatValue;
};

export const convertPrice = ({
  baseCurrency,
  exchangeRates,
  finalCurrency,
  price,
}: {
  baseCurrency: EnumOrLiteral<Currency>;
  exchangeRates: ExchangeRateOvrutils[];
  finalCurrency: EnumOrLiteral<Currency>;
  price: number;
}) => {
  const conversionRate = findExchangeRate(
    exchangeRates,
    finalCurrency,
    baseCurrency,
  );

  return price / conversionRate;
};

type ComputeTotalPriceArgs = {
  exchangeRateArray: InputExchangeRateOvrutils[];
  priceArray: InputPriceOvrutils[];
  targetCurrency: EnumOrLiteral<Currency>;
  withVat?: boolean;
};

export type CheckPriceArrayExchangeRatesCompatibilityArgs = {
  exchangeRateArray: InputExchangeRateOvrutils[];
  priceArray: InputPriceOvrutils[];
  targetCurrency: EnumOrLiteral<Currency>;
};

export const checkPriceArrayExchangeRatesCompatibility = (
  args: CheckPriceArrayExchangeRatesCompatibilityArgs,
): boolean => {
  if (args.priceArray.some((p) => !p.currency)) {
    throw new OvrseaDataError(
      `OVRUTILS :: one price has an undefined currency: ${JSON.stringify(
        args,
        null,
        2,
      )}`,
    );
  }

  return args.priceArray.every(
    (p) =>
      !!findExchangeRate(
        args.exchangeRateArray,
        args.targetCurrency,
        p.currency,
      ),
  );
};

export const calculateTotalPriceWithCurrency = (
  args: ComputeTotalPriceArgs,
) => {
  if (args.priceArray.length === 0) {
    return 0;
  }
  try {
    checkPriceArrayExchangeRatesCompatibility({
      exchangeRateArray: args.exchangeRateArray,
      priceArray: args.priceArray,
      targetCurrency: args.targetCurrency,
    });
    const totalPrice = sumBy(args.priceArray, (price) => {
      const conversionRate = findExchangeRate(
        args.exchangeRateArray,
        args.targetCurrency,
        price.currency,
      );

      return calculatePriceInCurrency({
        conversionRate,
        price,
        withVat: args.withVat,
      });
    });

    return roundValue(totalPrice);
  } catch (e) {
    console.log(
      `OVRUTILS :: Missing exchange rates in prices: ${JSON.stringify(
        args,
        null,
        2,
      )}`,
    );
    throw e;
  }
};

export type CalculatePricesRatioWithCurrencyArgs = {
  digits?: number;
  exchangeRateArray: ExchangeRateOvrutils[];
  pricesArray1: PriceOvrutils[];
  pricesArray2: PriceOvrutils[];
  targetCurrency: EnumOrLiteral<Currency>;
  withVat?: boolean;
};

export const calculatePricesRatioWithCurrencyInPercent = (
  args: CalculatePricesRatioWithCurrencyArgs,
): number => {
  const totalArray1 = calculateTotalPriceWithCurrency({
    ...args,
    priceArray: args.pricesArray1,
  });
  const totalArray2 = calculateTotalPriceWithCurrency({
    ...args,
    priceArray: args.pricesArray2,
  });

  return roundValue((totalArray1 / totalArray2) * 100, args.digits);
};

export const calculatePercentage = (
  percent?: null | number,
  of?: null | number,
): number =>
  isNotDefined(percent) || isNotDefined(of) || of <= 0
    ? 0
    : Math.floor((percent / of) * 100);

const currencyDictionnary = keyBy(
  currencies as Currency[],
  (arg) => arg,
) as Record<Currency, Currency>;

// Add more symbol when needed
const currencyCodeToSymbol: Record<Currency, string> = {
  ...currencyDictionnary,
  EUR: "€",
  GBP: "£",
  USD: "$",
};

export const convertCurrencyCodeToSymbol = (
  currencyCode: EnumOrLiteral<Currency>,
): string => {
  return currencyCodeToSymbol[currencyCode];
};
