import type { DbPricingSteps } from "../sharedTypes";

type MinimalPrice = {
  category: DbPricingSteps;
  name?: string;
  ovrseaName?: string;
  priceName?: string;
};

const isOvrseaNameFreight = <Price extends MinimalPrice>(price: Price) =>
  price.category === "freight" &&
  price.ovrseaName &&
  (price.ovrseaName.toLowerCase().includes("freight") ||
    price.ovrseaName.toLowerCase().includes("fret"));

const isClientNameFreight = <Price extends MinimalPrice>(price: Price) =>
  price.category === "freight" &&
  price.name &&
  (price.name.toLowerCase().includes("freight") ||
    price.name.toLowerCase().includes("fret"));

const isPriceNameFreight = <Price extends MinimalPrice>(price: Price) =>
  price.category === "freight" &&
  price.priceName &&
  (price.priceName.toLowerCase().includes("freight") ||
    price.priceName.toLowerCase().includes("fret"));

export const findFreightPrice = <Price extends MinimalPrice>(
  pricesArray: Price[],
) => {
  const freightPriceBasedOnOvrseaName = pricesArray.find(isOvrseaNameFreight);
  const freightPriceBasedOnClientName = pricesArray.find(isClientNameFreight);
  const freightPriceBasedOnPriceName = pricesArray.find(isPriceNameFreight);

  return (
    freightPriceBasedOnOvrseaName ??
    freightPriceBasedOnClientName ??
    freightPriceBasedOnPriceName
  );
};
