import { uniq } from "lodash-es";
import { type Currency, findFreightPrice, isDefined } from "../..";
import type { DbPricingSteps } from "../sharedTypes";

const isFreightPriceValid = <
  Accessor extends "priceCurrency" | "rateCurrency",
  Price extends { [Key in Accessor]?: Currency | null },
>(
  currencyAccessor: Accessor,
  price: Price | undefined,
): price is { [Key in Accessor]: Currency } & Price =>
  isDefined(price) && isDefined(price[currencyAccessor]);

const isPriceValid =
  <
    Accessor extends "priceCurrency" | "rateCurrency",
    Price extends { [Key in Accessor]?: Currency | null },
  >(
    currencyAccessor: Accessor,
  ) =>
  (price: Price): price is { [Key in Accessor]: Currency } & Price =>
    isDefined(price[currencyAccessor]);

export const determineCategoryCurrencyNewPrices = <
  Price extends {
    category: DbPricingSteps;
    priceCurrency: Currency;
    priceQuantity: number;
    unitPrice: number;
  },
>(
  prices: Price[],
  category: DbPricingSteps | null,
) => {
  const allCurrencies = prices.map(({ priceCurrency }) => priceCurrency);
  const uniqDefinedCurrencyArray = uniq(allCurrencies);

  const freightPrice = findFreightPrice(prices);

  if (uniqDefinedCurrencyArray.length === 0) {
    return "EUR";
  }
  if (uniqDefinedCurrencyArray.length === 1 && uniqDefinedCurrencyArray[0]) {
    return uniqDefinedCurrencyArray[0];
  }

  if (category === "freight" && freightPrice) {
    return freightPrice.priceCurrency;
  }

  const hasEur = uniqDefinedCurrencyArray.find(
    (currency) => currency === "EUR",
  );

  if (hasEur) {
    return "EUR";
  }
  const hasUsd = uniqDefinedCurrencyArray.find(
    (currency) => currency === "USD",
  );

  if (hasUsd) {
    return "USD";
  }

  return "EUR";
};

export const determineCategoryCurrency = <
  Accessor extends "priceCurrency" | "rateCurrency",
>(
  prices: ({
    category: DbPricingSteps;
    name: string;
    ovrseaName?: string;
  } & { [Key in Accessor]?: Currency | null })[],
  currencyAccessor: Accessor,
  category: DbPricingSteps | null,
): Currency => {
  const validPrices = prices.filter(isPriceValid(currencyAccessor));
  const allCurrencies = validPrices.map((price) => price[currencyAccessor]);
  const uniqDefinedCurrencyArray: Currency[] = uniq(allCurrencies);

  const freightPrice = findFreightPrice(prices);

  if (uniqDefinedCurrencyArray.length === 0) {
    return "EUR";
  }
  if (uniqDefinedCurrencyArray.length === 1 && uniqDefinedCurrencyArray[0]) {
    return uniqDefinedCurrencyArray[0];
  }

  if (
    category === "freight" &&
    isFreightPriceValid(currencyAccessor, freightPrice)
  ) {
    return freightPrice[currencyAccessor];
  }

  const hasEur = uniqDefinedCurrencyArray.find(
    (currency) => currency === "EUR",
  );

  if (hasEur) {
    return "EUR";
  }
  const hasUsd = uniqDefinedCurrencyArray.find(
    (currency) => currency === "USD",
  );

  if (hasUsd) {
    return "USD";
  }

  return "EUR";
};
