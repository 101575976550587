import type React from "react";
import { useMemo, useRef } from "react";
import type { NumberFieldStateOptions } from "@react-stately/numberfield";
import { useNumberFieldState } from "@react-stately/numberfield";
import { useNumberField } from "@react-aria/numberfield";
import type { NumberInputProps } from "../NumberInput";

const replaceDecimalSeparator = (value: string = "") => {
  // when copy-pasting a value in english notation over 999 and with decimals (e.g. 1,234.56)
  if (value.includes(".") && value.includes(",")) {
    return value.replaceAll(",", "");
  }

  // allows "," when typing without decimals yet and replace the comma with a dot
  return value.replaceAll(",", ".");
};

export const useNumberInput = (props: NumberInputProps) => {
  const inputRef = useRef(null);

  const stateOptions = useMemo<NumberFieldStateOptions>(
    () => ({
      defaultValue: props.value,
      formatOptions: {
        ...props.formatOptions,
        useGrouping: false,
      },
      isDisabled: props.isDisabled,
      isInvalid: props.isErrored,
      isRequired: props.isRequired,
      locale: "en-US",
      maxValue: props.maxValue,
      minValue: props.minValue,
      onChange: props.onChange,
      step: props.step,
      value: props.value,
    }),
    [
      props.formatOptions,
      props.isDisabled,
      props.isErrored,
      props.isRequired,
      props.value,
      props.maxValue,
      props.minValue,
      props.step,
    ],
  );

  const state = useNumberFieldState(stateOptions);

  const {
    decrementButtonProps,
    groupProps,
    incrementButtonProps,
    inputProps: { onChange: onInputChange, onKeyDown, ...inputProps },
  } = useNumberField(
    props,
    {
      ...state,
      validate: (value) => state.validate(replaceDecimalSeparator(value)),
    },
    inputRef,
  );

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) =>
    onInputChange?.({
      ...event,
      target: {
        ...event.target,
        value: replaceDecimalSeparator(event.target.value),
      },
    });

  return {
    decrementButtonProps,
    groupProps,
    incrementButtonProps,
    inputProps: {
      onChange,
      ...inputProps,
    },
    inputRef,
    onInputChange,
  };
};
